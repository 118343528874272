import { Response } from '@types';
import { call, put, retry, takeLatest } from 'redux-saga/effects';

import {
  getGuideData,
  getGuidePDFUrl,
  patchGuideSADT,
  getClinichHealthInsuranceProfessionalExecutant,
  getClinichHealthInsuranceHiredExecutant,
  getClinicHealthShowData,
  getEventApi,
} from 'features/tissInvoicing/services/sadt';
import {
  ActionKeys,
  GeneratePDFResponse,
  GuideSADT,
} from 'features/tissInvoicing/types';
import getSnackbarPayload from 'features/tissInvoicing/utils/getSnackbarPayload';
import { captureException } from 'shared/utils/handlerErrors';
import { actions } from '.';
import { RETRY_DELAY_MS, RETRY_TIMES } from '../constants';
import {
  normalizeGuideSADTPatchPayload,
  parseDataClinicHealth,
} from './normalizers';
import { showPatientService } from 'features/tissInvoicing/services/common';
import { PatientParams } from 'features/tissInvoicing/types/sadt/patients';
import { EventParams } from 'features/tissInvoicing/types/sadt/event';

type SaveGuideAction = ReturnType<typeof actions.saveGuide>;
type PrintGuideAction = ReturnType<typeof actions.printGuide>;
type FetchGuideDataAction = ReturnType<typeof actions.fetchGuideData>;
type FetchClinicHealth = ReturnType<typeof actions.fetchClinicHealth>;
type NewGuideSADT = Omit<GuideSADT, 'insurance_id' | 'physician_id'> & {
  insurance_id: number;
  physician_id: number;
};
type GetClinicHealthHiredExecutant = ReturnType<
  typeof actions.getClinicHealthHiredExecutant
>;
type FetchClinicHealthShowAction = ReturnType<
  typeof actions.fetchClinicHealthShow
>;
type GetPatientData = ReturnType<typeof actions.getPatientData>;
type FetchEventAction = ReturnType<typeof actions.fetchEventData>;

export function* fetchGuideDataWorker({
  payload: { eventId },
}: FetchGuideDataAction) {
  try {
    yield put(
      actions.setLoadingStatus({ key: ActionKeys.FetchGuide, value: true }),
    );

    const { data, status }: Response<NewGuideSADT> = yield retry(
      RETRY_TIMES,
      RETRY_DELAY_MS,
      getGuideData,
      eventId,
    );

    if (status === 201) {
      yield put(
        actions.setSnackbarContent(
          getSnackbarPayload('Guia gerada com sucesso', 'success'),
        ),
      );
    }

    yield put(actions.setGuideData(data));
  } catch (error) {
    captureException(error);
  } finally {
    yield put(
      actions.setLoadingStatus({ key: ActionKeys.FetchGuide, value: false }),
    );
  }
}

export function* saveGuideWorker({
  payload: { guideId, data, professionalExecutant, hiredExecutant },
}: SaveGuideAction) {
  try {
    yield put(
      actions.setLoadingStatus({
        key: ActionKeys.Save,
        value: true,
      }),
    );

    const nullTussProcedureIndex: number = data?.procedures?.findIndex(
      ({ tuss_procedure_id }) => tuss_procedure_id === null,
    );

    if (nullTussProcedureIndex >= 0) {
      const position = nullTussProcedureIndex + 1;
      throw new Error(`O procedimento ${position} está sem o vínculo TUSS.`);
    }

    const normalizedPayload = normalizeGuideSADTPatchPayload(data);

    const { data: guide }: Response<GuideSADT> = yield call(
      patchGuideSADT,
      guideId,
      {
        ...normalizedPayload,
        ...parseDataClinicHealth(professionalExecutant, hiredExecutant),
      },
    );

    yield put(actions.setGuideData(guide));
    yield put(
      actions.setSnackbarContent(
        getSnackbarPayload('Guia salva com sucesso', 'success'),
      ),
    );
  } catch (error) {
    const errorMessage = (error as Error).message?.includes('vínculo TUSS')
      ? (error as Error).message
      : 'Houve um problema ao salvar a guia';

    yield put(
      actions.setSnackbarContent(getSnackbarPayload(errorMessage, 'error')),
    );
    captureException(error);
  } finally {
    yield put(
      actions.setLoadingStatus({
        key: ActionKeys.Save,
        value: false,
      }),
    );
  }
}

export function* printSadtFormWorker({
  payload: { guideId, eventId, data, professionalExecutant, hiredExecutant },
}: PrintGuideAction) {
  try {
    yield put(
      actions.setLoadingStatus({
        key: ActionKeys.Print,
        value: true,
      }),
    );
    yield put(actions.setGuidePDFUrl(''));

    // Salvar guia SP/SADT
    try {
      const normalizedPayload = normalizeGuideSADTPatchPayload(data);
      const { data: guide }: Response<GuideSADT> = yield call(
        patchGuideSADT,
        guideId,
        {
          ...normalizedPayload,
          ...parseDataClinicHealth(professionalExecutant, hiredExecutant),
        },
      );

      yield put(actions.setGuideData(guide));
    } catch {
      throw new Error('Houve um problema ao salvar a guia');
    }

    // Gerar PDF da guia SP/SADT
    try {
      const {
        data: { url },
      }: Response<GeneratePDFResponse> = yield call(getGuidePDFUrl, eventId);
      yield put(actions.setGuidePDFUrl(url));
      yield put(actions.setPrintModalOpen(true));
    } catch {
      throw new Error('Houve um problema ao imprimir a guia');
    }
  } catch (error) {
    if (error instanceof Error) {
      yield put(
        actions.setSnackbarContent(getSnackbarPayload(error?.message, 'error')),
      );
      captureException(error);
    }
  } finally {
    yield put(
      actions.setLoadingStatus({
        key: ActionKeys.Print,
        value: false,
      }),
    );
  }
}

export function* fetchClinicHealth({
  payload: { insurance_id, physician_id },
}: FetchClinicHealth) {
  try {
    yield put(actions.resetClinicHealth());

    const { data: professionalExecutant } = yield call(
      getClinichHealthInsuranceProfessionalExecutant,
      insurance_id,
      physician_id,
    );

    const { data: hiredExecutant } = yield call(
      getClinichHealthInsuranceHiredExecutant,
      insurance_id,
      physician_id,
    );

    yield put(
      actions.setClinicHealth({
        hiredExecutant,
        professionalExecutant,
      }),
    );
  } catch (error) {
    captureException(error);
  }
}

export function* getClinicHealthInsuranceHiredExecutant({
  payload: { insurance_id, physician_id },
}: GetClinicHealthHiredExecutant) {
  try {
    yield put(actions.resetClinicHealth());
    yield put(
      actions.setLoadingStatus({
        key: ActionKeys.FetchClinicHealthHiredExecutant,
        value: true,
      }),
    );

    const { data } = yield call(
      getClinichHealthInsuranceHiredExecutant,
      insurance_id,
      physician_id,
    );

    yield put(
      actions.setClinicHealthWithType({ type: 'hiredExecutant', data }),
    );
  } catch (error) {
    captureException(error);
  } finally {
    yield put(
      actions.setLoadingStatus({
        key: ActionKeys.FetchClinicHealthHiredExecutant,
        value: false,
      }),
    );
  }
}

export function* fetchClinicHealthShowWorker({
  payload: { insurance_id },
}: FetchClinicHealthShowAction) {
  try {
    yield put(actions.resetClinicHealth());

    yield put(
      actions.setLoadingStatus({
        key: ActionKeys.FetchClinicHealthShow,
        value: true,
      }),
    );

    const { data } = yield call(getClinicHealthShowData, insurance_id);

    yield put(actions.setClinicHealthWithType({ type: 'show', data }));
  } catch (error) {
    captureException(error);
  } finally {
    yield put(
      actions.setLoadingStatus({
        key: ActionKeys.FetchClinicHealthShow,
        value: false,
      }),
    );
  }
}

export function* showPatientWorker({
  payload: { patient_id },
}: GetPatientData) {
  try {
    yield put(
      actions.setLoadingStatus({
        key: ActionKeys.GetPatient,
        value: true,
      }),
    );

    const { data }: Response<PatientParams> = yield retry(
      RETRY_TIMES,
      RETRY_DELAY_MS,
      showPatientService,
      patient_id,
    );

    yield put(actions.setPatient(data));
  } catch (error) {
    captureException(error);
  } finally {
    yield put(
      actions.setLoadingStatus({
        key: ActionKeys.GetPatient,
        value: false,
      }),
    );
  }
}

export function* fetchEventWorker({ payload: { eventId } }: FetchEventAction) {
  try {
    const { data }: Response<EventParams> = yield retry(
      RETRY_TIMES,
      RETRY_DELAY_MS,
      getEventApi,
      eventId,
    );

    yield put(actions.setEventData(data));
  } catch (error) {
    captureException(error);
    yield put(
      actions.setSnackbarContent(
        getSnackbarPayload('Houve um problema para carregar a guia', 'error'),
      ),
    );
  }
}

export default function* watchSadtWorker() {
  yield takeLatest(actions.fetchGuideData, fetchGuideDataWorker);
  yield takeLatest(actions.saveGuide, saveGuideWorker);
  yield takeLatest(actions.printGuide, printSadtFormWorker);
  yield takeLatest(actions.fetchClinicHealth, fetchClinicHealth);
  yield takeLatest(actions.fetchClinicHealthShow, fetchClinicHealthShowWorker);
  yield takeLatest(
    actions.getClinicHealthHiredExecutant,
    getClinicHealthInsuranceHiredExecutant,
  );
  yield takeLatest(actions.getPatientData, showPatientWorker);
  yield takeLatest(actions.fetchEventData, fetchEventWorker);
}
