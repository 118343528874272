import React from 'react';
import { Box } from '@iclinic/design-system';

import CardProfileLoader from './profile/CardProfileLoader';

const SellerRegisterProfileLoader = () => (
  <Box mb={2}>
    <CardProfileLoader />
  </Box>
);

export default SellerRegisterProfileLoader;
