/* eslint-disable jsx-a11y/media-has-caption */
import React, { useEffect, useRef, useState } from 'react';
import {
  Button,
  MaterialIcons as Icons,
  Tooltip,
} from '@iclinic/design-system';
import SpeechRecognition, {
  useSpeechRecognition,
} from 'react-speech-recognition';
import { captureException } from '@sentry/browser';
import { useDispatch } from 'react-redux';

import { actions } from '../../state';
import {
  SpeechAreaContainer,
  Header,
  Description,
  DeleteButton,
  ButtonsWrapper,
  TranscriptionWrapper,
  Transcription,
} from './SpeechArea.styles';

const { updateTranscript, updateResumeMarkdown } = actions;

const SpeechArea = () => {
  const dispatch = useDispatch();
  const mediaRecorderRef = useRef<MediaRecorder | null>();
  const { transcript, listening, resetTranscript, isMicrophoneAvailable } =
    useSpeechRecognition();
  const audioChunksRef = useRef<Blob[]>([]);
  const [audioUrl, setAudioUrl] = useState('');

  const clearOldResume = () => dispatch(updateResumeMarkdown(''));

  const handleResetAll = () => {
    resetTranscript();
    audioChunksRef.current = [];
    setAudioUrl('');
    clearOldResume();
  };

  const handleRecordingTrigger = (recording: boolean) => {
    if (
      mediaRecorderRef.current &&
      recording &&
      SpeechRecognition.startListening
    ) {
      clearOldResume();
      mediaRecorderRef.current.start();
      SpeechRecognition.startListening({
        continuous: true,
        language: 'pt-BR',
      }).catch((error) => captureException(error));
    }

    if (
      mediaRecorderRef.current &&
      !recording &&
      SpeechRecognition.stopListening
    ) {
      mediaRecorderRef.current.stop();
      SpeechRecognition.stopListening();
    }
  };

  useEffect(() => {
    if (isMicrophoneAvailable) {
      navigator.mediaDevices
        .getUserMedia({ audio: true })
        .then((stream) => {
          mediaRecorderRef.current = new MediaRecorder(stream);

          mediaRecorderRef.current.ondataavailable = (event) => {
            audioChunksRef.current.push(event.data);
          };

          mediaRecorderRef.current.onstop = () => {
            const audioBlob = new Blob(audioChunksRef.current, {
              type: 'audio/wav',
            });
            setAudioUrl(URL.createObjectURL(audioBlob));
          };
        })
        .catch((error) => captureException(error));
    }
  }, [isMicrophoneAvailable]);

  useEffect(() => {
    dispatch(updateTranscript(transcript));
  }, [dispatch, transcript]);

  return (
    <SpeechAreaContainer>
      <Header>
        <Description>Transcrever Anamnese</Description>
      </Header>
      <ButtonsWrapper>
        <Button
          color="primary"
          size="small"
          startIcon={listening ? <Icons.MicOff /> : <Icons.Mic />}
          onClick={() => handleRecordingTrigger(!listening)}
        >
          {`${listening ? 'Parar' : 'Iniciar'} Transcrição`}
        </Button>
        <Tooltip title="Limpar dados da transcrição" placement="top">
          <DeleteButton
            size="small"
            disabled={listening}
            onClick={handleResetAll}
          >
            <Icons.Delete />
          </DeleteButton>
        </Tooltip>
      </ButtonsWrapper>
      <TranscriptionWrapper>
        <audio src={audioUrl} controls />
        <Transcription>{transcript}</Transcription>
      </TranscriptionWrapper>
    </SpeechAreaContainer>
  );
};

export default SpeechArea;
