import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Form, FormikProvider, useFormik } from 'formik';
import { Button } from '@iclinic/design-system';

import { statusSelector } from '../state/selectors';
import { CboInput } from '../components/index';
import { Actions } from '../components/Actions/Actions';
import * as S from './InputStep.styles';
import { RequestStatus } from 'shared/constants/State';
import { specialtyFormValidation } from '../validations';
import { submitSpecialty, backStep } from '../state/reducer';
import { SpecialtyStepValues, SignupSteps } from '../state/types';
import { ButtonSubmit } from '../components/Signup.styles';
import { useCheckStep } from '../hooks/useCheckStep';
import { trackingStepNames } from '../constants';
import { trackSignupStep } from '../trackSignupUtils';

const PersonalStepContainer = () => {
  const status = useSelector(statusSelector);
  const dispatch = useDispatch();
  useCheckStep({ navigationStep: SignupSteps.Specialty });

  const handleSubmit = (values: SpecialtyStepValues) => {
    dispatch(submitSpecialty(values.cbo));
  };

  const handleBackStep = () => {
    const previousStep = SignupSteps.ProfessionCount;
    trackSignupStep({
      name: trackingStepNames[previousStep].back,
    });
    dispatch(backStep(previousStep));
  };

  const formik = useFormik({
    initialValues: {
      cbo: '',
    },
    onSubmit: handleSubmit,
    validationSchema: specialtyFormValidation,
  });
  const isLoading = status === RequestStatus.Pending;
  const isDisabled = isLoading || !formik.values.cbo;

  return (
    <FormikProvider value={formik}>
      <Form>
        <S.FormContainer>
          <S.Heading variant="sm">
            Por fim, qual a sua especialização ou da clínica em que trabalha?
          </S.Heading>
          <S.Body variant="xs">
            Vamos melhorar a sua experiência e personalizar o seu prontuário
          </S.Body>
          <CboInput />
        </S.FormContainer>
        <Actions>
          <Button color="transparent" onClick={handleBackStep}>
            Voltar
          </Button>
          <ButtonSubmit
            color="primary"
            type="submit"
            variant="contained"
            disabled={isDisabled}
            isLoading={isLoading}
          >
            Iniciar
          </ButtonSubmit>
        </Actions>
      </Form>
    </FormikProvider>
  );
};

export default PersonalStepContainer;
