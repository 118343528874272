import * as Yup from 'yup';

const emailInvalid = 'Endereço de e-mail inválido';
const configEmailLimit =
  'Limite de e-mails atingido, só é possível ter 2 emails cadastrados';
const required = 'Campo obrigatório';

export const validationSellerEdit = Yup.object().shape({
  fieldEmailPayConfirm: Yup.string().email(emailInvalid),
  fieldEmailWeeklySummary: Yup.string().email(emailInvalid),
  paymentConfirmationEmails: Yup.array().max(2, configEmailLimit),
  weeklySummaryEmails: Yup.array().max(2, configEmailLimit),
  bank: Yup.string().required(required),
  agency: Yup.string().required(required),
  account: Yup.string().required(required),
});
