import * as Yup from 'yup';

import { SellerType } from '../constants';
import { onlyNumbers } from 'shared/utils/formatters';
import {
  isValidCPF,
  isValidFullDate,
  isValidCNPJ,
  isMinorOfAge,
} from 'shared/utils/validation';

const required = 'Campo obrigatório';
// eslint-disable-next-line no-template-curly-in-string
const max = 'O campo aceita o máximo de ${max} caracteres';

const SellerCommonFields = Yup.object().shape({
  picture: Yup.string().when(['initialPicture'], {
    is: (initialPicture) => initialPicture == null,
    then: Yup.string().required(required),
    otherwise: Yup.string(),
  }),
  email: Yup.string()
    .email('Digite um e-mail válido')
    .max(254, max)
    .required(required),
  name: Yup.string().trim().max(64, max).required(required),
  cpf: Yup.string()
    .required(required)
    .test(
      'isValidCPF',
      'CPF inválido',
      (value) => value && isValidCPF(onlyNumbers(value)),
    ),
  birthdate: Yup.mixed()
    .nullable()
    .required(required)
    .test(
      'isValidFullDate',
      'Data inválida',
      (value) => value && isValidFullDate(value),
    )
    .test(
      'isMinorOfAge',
      'Usuário menor de idade',
      (value) => value && isMinorOfAge(value),
    ),
  phone: Yup.string().required(required),
  councilName: Yup.string().required(required),
  councilNumber: Yup.string().max(10).required(required),
  councilRegion: Yup.string().required(required),
  cbo: Yup.mixed().required(required),
  type: Yup.string(),
});

const SellerPJ = Yup.object().shape({
  businessName: Yup.string().when('type', {
    is: SellerType.PJ,
    then: Yup.string().trim().max(255, max).required(required),
  }),
  businessDescription: Yup.string().when('type', {
    is: SellerType.PJ,
    then: Yup.string().trim().max(64, max).required(required),
  }),
  businessOpeningDate: Yup.mixed().when('type', {
    is: SellerType.PJ,
    then: Yup.mixed()
      .nullable()
      .required(required)
      .test(
        'isValidFullDate',
        'Data inválida',
        (value) => value && isValidFullDate(value),
      ),
  }),
  businessCnpj: Yup.string().when('type', {
    is: SellerType.PJ,
    then: Yup.string()
      .required(required)
      .test(
        'isValidCNPJ',
        'CNPJ inválido',
        (value) => value && isValidCNPJ(onlyNumbers(value)),
      ),
  }),
});

const SellerAddress = Yup.object().shape({
  zipCode: Yup.string()
    .required(required)
    .length(9, 'O campo precisa ter um cep válido'),
  street: Yup.string().trim().max(64, max).required(required),
  number: Yup.string().trim().max(8, max).required(required),
  neighborhood: Yup.string().trim().max(32, max).required(required),
  city: Yup.string().trim().max(64, max).required(required),
  state: Yup.string().required(required),
  complement: Yup.string().max(64, max),
});

export const validationSellerRegisterProfile =
  SellerCommonFields.concat(SellerPJ).concat(SellerAddress);

export const validationSellerRegisterBank = Yup.object().shape({
  sellerId: Yup.string().required(required),
  bank: Yup.string().required(required),
  agency: Yup.string().required(required),
  account: Yup.string().required(required),
  installments: Yup.string().required(required),
  minValue: Yup.number()
    .required(required)
    .moreThan(0, 'O valor não pode ser zero'),
  terms: Yup.boolean().test(
    'mustBeTrue',
    'É necessário aceitar os termos de uso',
    (value) => value,
  ),
});
