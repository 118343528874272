import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { captureException } from '@sentry/browser';
import { AxiosError } from 'axios';
import { ReCaptchaInstance } from 'recaptcha-v3';

import history from 'routes/history';
import * as actions from './reducer';
import {
  submitSignup,
  updateContact,
  verifyEmail,
  fetchFilteredListCBO,
  loadRecaptcha,
} from '../services';
import { SignupSteps, SignupStepsPaths, StoreStatePayload } from './types';
import {
  getCheckoutParam,
  getErrorCode,
  getReferralParams,
  getUtmsData,
} from '../utils';
import {
  getCboIdSelector,
  emailSelector,
  isAnotherHealthProfessionalSelector,
  signupSelector,
  submitDataSelector,
  professionSelector,
  isVariantSelector,
  isDoctorSelector,
  autoLoginSelector,
} from './selectors';
import { dispatchGaEvent } from 'shared/utils/googleAnalytics';
import { dispatchHubspotEvent } from 'shared/utils/hubspot';
import {
  RecaptchaEvents,
  SIGNUP_PAYWALL_KEY,
  SIGNUP_STORAGE_KEY,
  HUBSPOT_LOGIN_EVENT,
  trackingStepNames,
  urlSignupCampaign,
  urlSignupDefault,
  urlVariantCampaign,
} from '../constants';
import { UpdateHubspotData } from '../services/types';
import { trackSignupStep } from '../trackSignupUtils';

type PayloadCheckStep = ReturnType<typeof actions.checkStep>;
type PayloadSubmitEmail = ReturnType<typeof actions.submitEmail>;
type PayloadSubmitPersonal = ReturnType<typeof actions.submitPersonal>;
type PayloadBackStep = ReturnType<typeof actions.backStep>;
type PayloadSubmitProfession = ReturnType<typeof actions.submitProfession>;
type PayloadSubmitProfessionalCount = ReturnType<
  typeof actions.submitProfessionalCount
>;
type PayloadSubmitSpecialty = ReturnType<typeof actions.submitSpecialty>;

export function* pushUrl(url: string) {
  yield call([history, 'push'], {
    pathname: url,
    search: window.location.search,
  });
}

function* setPaywallFlag() {
  yield call(
    [localStorage, 'setItem'],
    SIGNUP_PAYWALL_KEY,
    JSON.stringify({
      show_paywall: true,
    }),
  );
}

export function* getRecaptchaToken(action: string) {
  try {
    const recaptcha: ReCaptchaInstance = yield call(loadRecaptcha);
    const token: string = yield call([recaptcha, 'execute'], action);
    if (token === null) {
      throw new Error('RecaptchaNullError');
    }
    return token;
  } catch (e) {
    if (e === null) {
      throw new Error('RecaptchaNullError');
    }

    if (!(e instanceof Error)) {
      throw new Error(String(e));
    }

    throw e;
  }
}

export function* storeState({ autoLogin }: StoreStatePayload = {}) {
  const signupState: ReturnType<typeof signupSelector> = yield select(
    signupSelector,
  );

  const saveState = {
    email: signupState.email,
    name: signupState.name,
    billingPhone: signupState.billingPhone,
    profession: signupState.profession,
    professionalCount: signupState.professionalCount,
    autoLogin,
  };

  yield call(
    [localStorage, 'setItem'],
    SIGNUP_STORAGE_KEY,
    JSON.stringify(saveState),
  );

  yield setPaywallFlag();
}

export function* checkVariantCampaign() {
  const path = window.location.pathname;
  const isVariantUrl = path.includes(`/${urlVariantCampaign}`);

  if (isVariantUrl) {
    return urlVariantCampaign;
  }

  const isVariant: boolean = yield select(isVariantSelector);
  return isVariant ? urlVariantCampaign : urlSignupDefault;
}

export function* checkStepWorker({
  payload: { progressStep, navigationStep },
}: PayloadCheckStep) {
  if (navigationStep > progressStep) {
    const campaignType: string = yield call(checkVariantCampaign);
    const baseUrl = `/new/${campaignType}`;
    const url = `${baseUrl}${SignupStepsPaths[SignupSteps.Email]}`;
    yield call(pushUrl, url);
  }
}

export function* updateHubspot(hubspotInfo: Omit<UpdateHubspotData, 'email'>) {
  const email: ReturnType<typeof emailSelector> = yield select(emailSelector);
  yield call(updateContact, { ...hubspotInfo, email });
}

export function* signupSubmit() {
  try {
    yield call(trackSignupStep, { name: 'submit' });
    const [coupon, referralCode, refSource] = getReferralParams();
    const checkout = getCheckoutParam();
    const queryParam = checkout === 'true' ? '?checkout=true' : '';

    const submitState: ReturnType<typeof submitDataSelector> = yield select(
      submitDataSelector,
    );

    const submitData = {
      ...submitState,
      ...(coupon && { coupon }),
      ...(referralCode && { referral: referralCode }),
      ...(refSource && { ref_source: refSource }),
      ...(checkout && { checkout }),
    };

    const {
      data: { auto_login },
    } = yield call(submitSignup, submitData);

    yield call(trackSignupStep, { name: 'success' });

    yield call(dispatchGaEvent, 'on_signup');
    yield call(dispatchGaEvent, 'on_login', getUtmsData());
    yield call(dispatchHubspotEvent, HUBSPOT_LOGIN_EVENT, {
      email: submitState.email,
      ...getUtmsData(),
    });
    yield call(storeState, { autoLogin: auto_login });
    yield call(
      [window.location, 'replace'],
      `/new/cadastro/iniciar-teste${queryParam}`,
    );
  } catch (e) {
    yield put(actions.requestError(getErrorCode(e as AxiosError)));
    yield call(captureException, e);
  }
}

export function identifyUrl(step: SignupSteps, campaignType: string): string {
  const baseUrl = `/new/${campaignType}`;
  let url = `${baseUrl}${SignupStepsPaths[step]}`;
  const path = window.location.pathname;

  if (path.includes(`/${urlSignupCampaign}`)) {
    url = url.replace('cadastro', urlSignupCampaign);
  }
  return url;
}

export function* goToStep(step: SignupSteps) {
  yield put(actions.setStep(step));
  const campaignType: string = yield call(checkVariantCampaign);
  const url = identifyUrl(step, campaignType);
  yield call(pushUrl, url);
  yield put(actions.resetStatus());
}

export function* submitEmailWorker({ payload }: PayloadSubmitEmail) {
  try {
    yield put(actions.setEmailAndPassword(payload));

    const captcha: string = yield call(
      getRecaptchaToken,
      RecaptchaEvents.Email,
    );
    yield call(verifyEmail, payload.email, captcha);

    yield call(storeState);
    yield call(goToStep, SignupSteps.Personal);
  } catch (e) {
    yield put(actions.requestError(getErrorCode(e as AxiosError)));
    yield call(captureException, e);
  }
}

export function* submitProfessionWorker({
  payload: profession,
}: PayloadSubmitProfession) {
  try {
    yield put(actions.initRequest());

    yield call(updateHubspot, { job_title: profession });

    const nextStep = SignupSteps.ProfessionCount;
    yield call(trackSignupStep, {
      name: trackingStepNames[nextStep].next,
    });

    yield call(storeState);
    yield call(goToStep, SignupSteps.ProfessionCount);
  } catch (e) {
    yield put(actions.requestError(getErrorCode(e as AxiosError)));
    yield call(captureException, e);
  }
}

export function* submitPersonalWorker({ payload }: PayloadSubmitPersonal) {
  try {
    yield put(actions.setPersonal(payload));

    const { name, billingPhone } = payload;

    yield call(updateHubspot, { firstname: name, phone: billingPhone });

    yield call(storeState);
    yield call(goToStep, SignupSteps.Job);
  } catch (e) {
    yield put(actions.requestError(getErrorCode(e as AxiosError)));
    yield call(captureException, e);
  }
}

export function* submitProfessionalCountWorker({
  payload: professionalCount,
}: PayloadSubmitProfessionalCount) {
  try {
    yield put(actions.initRequest());

    if (professionalCount) {
      yield call(updateHubspot, { total_users: professionalCount });
    }

    yield call(storeState);

    const isAnotherHealthProfession: ReturnType<
      typeof isAnotherHealthProfessionalSelector
    > = yield select(isAnotherHealthProfessionalSelector);
    const isDoctor: ReturnType<typeof isDoctorSelector> = yield select(
      isDoctorSelector,
    );

    if (isAnotherHealthProfession || isDoctor) {
      const nextStep = SignupSteps.Specialty;
      yield call(trackSignupStep, { name: trackingStepNames[nextStep].next });
      yield call(goToStep, nextStep);
      return;
    }

    yield call(signupSubmit);
  } catch (e) {
    yield put(actions.requestError(getErrorCode(e as AxiosError)));
    yield call(captureException, e);
  }
}

export function* submitSpecialtyWorker({
  payload: cboTerm,
}: PayloadSubmitSpecialty) {
  try {
    yield put(actions.setSpecialty(cboTerm));

    const cbo: number = yield select(getCboIdSelector, cboTerm);
    const profession: ReturnType<typeof professionSelector> = yield select(
      professionSelector,
    );
    yield call(updateHubspot, { job_title: profession, cbo });

    yield call(signupSubmit);
  } catch (e) {
    yield put(actions.requestError(getErrorCode(e as AxiosError)));
    yield call(captureException, e);
  }
}

export function* filterListCBOWorker({ payload }: { payload: string }) {
  try {
    const { data } = yield call(fetchFilteredListCBO, payload);
    yield put(actions.submitListCBO(data.objects));
  } catch (e) {
    yield call(captureException, e);
  }
}

export function* backStepWorker({ payload: step }: PayloadBackStep) {
  yield call(goToStep, step);
}

export function* submitAutoLoginWorker() {
  const auto_login: string = yield select(autoLoginSelector);
  const isCheckout = getCheckoutParam();
  const nextPage =
    isCheckout === 'true' ? 'configuracoes/assinatura' : 'agenda';

  yield call([localStorage, 'removeItem'], SIGNUP_STORAGE_KEY);
  yield call([window.location, 'replace'], `${auto_login}&next=/${nextPage}/`);
}

export default function* signupSagas() {
  yield all([
    takeLatest(actions.checkStep, checkStepWorker),
    takeLatest(actions.submitEmail, submitEmailWorker),
    takeLatest(actions.submitPersonal, submitPersonalWorker),
    takeLatest(actions.backStep, backStepWorker),
    takeLatest(actions.submitProfession, submitProfessionWorker),
    takeLatest(actions.submitProfessionalCount, submitProfessionalCountWorker),
    takeLatest(actions.submitSpecialty, submitSpecialtyWorker),
    takeLatest(actions.filterListCBO, filterListCBOWorker),
    takeLatest(actions.triggerSubmitAutoLogin, submitAutoLoginWorker),
  ]);
}
